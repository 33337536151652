<template>
    <div class="container-fluid pl-5 pr-5 content-container">
        <div class="row">
            <div class="col-sm-12 bg-light shadow  p-3">
                <p class="heading">Review your order</p>
                <hr/>
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-success" v-if="success!=''">
                            {{success}}
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="row pr-5">
                            <div class="col-sm-12 shadow border border-secondary ml-3 p-3">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="heading">Shipping Address</p>
                                        <DeliveryInformation></DeliveryInformation>

                                    </div>
                                    <div class="col-sm-6">
                                        <p class="heading">Billing Address</p>
                                        <BillingInformation></BillingInformation>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 border border-secondary shadow ml-3 p-3 mt-4">
                                <p class="heading">Your order will be delivered today</p>
                                <div class="table-responsive" v-if="$store.getters.cart.items>0">
                                    <table class="table table-hover">

                                        <tr v-for="(item,index) in $store.getters.cart.list" v-bind:key="index">
                                            <th style="max-width: 150px;width:150px;"><img
                                                    :src="$store.getters.rootPath+'item?image='+item.image"
                                                    class="img-fluid" alt=""></th>
                                            <th>
                                                <h4>{{item.name}}</h4>
                                                <p class="text-danger">${{(item.price * item.quantity).toFixed(2)}}</p>
                                                <p><strong>Quantity  : {{item.quantity}} &nbsp; </strong>
                                                    <a href="#"
                                                       @click.prevent="changeQuantity(item.quantity,index)">Change</a>
                                                    &nbsp; <a href="#" class="text-danger"
                                                              @click.prevent="removeItem(item.item_id)">Remove</a>
                                                </p>
                                                <div class="col-sm-12" v-if="change_index==index">
                                                    <div class="form-group">
                                                        <input type="number" :max="item.max" min="0" value=""
                                                               v-model="quantity"
                                                               class="form-control" style="width:150px;">
                                                    </div>
                                                    <input type="button" @click="updatePrice"
                                                           class="btn btn-outline-info btn-sm "
                                                           value="Update quantity">
                                                </div>
                                            </th>

                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 ">
                        <div class="col-sm-12 border border-secondary shadow p-3">

                            <p class="heading mt-3">Order Summary</p>
                            <table class="table">
                                <tr>
                                    <td class="border-top-0"> Items</td>
                                    <th class="text-right border-top-0">${{$store.getters.cart.price.toFixed(2)}}</th>
                                </tr>
                                <tr>
                                    <td class="border-top-0">Shipping and handling</td>
                                    <th class="text-right border-top-0 border-bottom">$00.00</th>
                                </tr>
                                <tr>
                                    <td class="border-top-0">Total before tax</td>
                                    <th class="text-right border-top-0">${{$store.getters.cart.price.toFixed(2)}}</th>
                                </tr>
                                <tr>
                                    <td class="border-top-0">Estimated tax to be collected</td>
                                    <th class="text-right border-top-0">$00.00</th>
                                </tr>
                                <tr>
                                    <th class="heading">
                                        Order total
                                    </th>
                                    <th class="heading text-right">$ {{$store.getters.cart.price.toFixed(2)}}</th>
                                </tr>
                                <tr>
                                    <th>
                                        Payment Method
                                    </th>
                                    <th>
                                        <select v-model="selected_payment_method" class="form-control" id="">
                                            <option v-for="(method,id,key) in payment_methods" :value="id" :key="key">{{method}}
                                            </option>
                                        </select>
                                        <span class="text-danger">{{errors['payment_method'][0]}}</span>
                                    </th>
                                </tr>
                            </table>
                            <input type="button" class="btn btn-block btn-sm btn-outline-info" :disabled="loading"
                                   v-if="$store.getters.cart.items>0" @click="sendOrder" value="Place your order">
                        </div>
                    </div>

                    <div class="col-12">

                    </div>

                </div>

                <form :action="paypal_path" style="display: none;" method="post" ref="paypal">


                </form>

                <div class="row mt-5">
                    <div class="col-12">

                        <router-link :to="{name:'shop'}" class="btn btn-sm btn-outline-info mb-2 shadow">View products
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

    import DeliveryInformation from '@/views/shop/DeliveryInformation';
    import BillingInformation from '@/views/shop/BillingInformation';
    import Loader from '../../loader'
    import {Paths} from '../../paths'
    export default{
        extends: Loader,
        components: {DeliveryInformation, BillingInformation},
        data(){
            return {
                key: '',
                order_id: '0',
                success: '',
                quantity: 0,
                change_index: -1,
                selected_payment_method: 0,
                payment_methods: {
                    0: 'Select Payment Method',
                    1: 'Cash on delivery',
                    2: 'Pay with Paypal or debit/credit card'
                },
                errors: {
                    payment_method: [],
                },
                paypal_path: 'https://ushalal-services.dms-cacfpsfsp.com/paypal',
            }
        },
        methods: {
            sendOrder(){
                let app = this;
                this.loading = true;


                this.axios.post(Paths.order.send, app.$store.getters.cart).then((response) => {
                    app.success = 'You order is received, we will process it soon';
//                    app.$store.commit('clearCart');

                    app.key = response.data.key;
                    app.order_id = response.data.order.id;
                    app.$store.commit('setOrderId', parseInt(app.order_id));
                    app.$store.commit('saveCart');

                    if(app.$store.getters.cart.payment_method==2){
                        app.$refs['paypal'].innerHTML = '<input type="text" value="' + app.order_id + '" name="order_id" readonly="readonly">';
                        setTimeout(app.$refs['paypal'].submit(), 500);
                    }


                }).catch((response) => {
                    app.errors = response.response.data.errors;

                }).finally(() => {
                    app.loading = false;
                });
            },
            changeQuantity(quantity, index){
                this.quantity = quantity;
                this.change_index = index;
            },
            updatePrice(){

                let item_id = this.$store.getters.cart.list[this.change_index].item_id;
                this.$store.commit('addQuantityToCart', {quantity: parseInt(this.quantity), id: item_id});
                this.$store.commit('refreshCart');
                this.change_index = -1;
                this.$store.commit('saveCart');
            },
            removeItem(item_id){
                this.$store.commit('removeFromCart', {id: item_id});
                this.$store.commit('refreshCart');
                this.$store.commit('saveCart');
            }
        },
        watch: {
            selected_payment_method: function (newval) {
                this.$store.commit('setPaymentMethodInCart', parseInt(newval));
                this.$store.commit('saveCart');
            }
        },
        mounted(){

        }
    }
</script>